<template>
  <article>
    <div class="hero">
      <h1>Transparência</h1>
      <p class="subh">
        Acesse publicações periódicas, documentos e demais prestações de contas
      </p>
    </div>

    <section class="secao-transparencia container-base">
      <div v-for="topico in topicos" :key="topico.titulo">
        <h1>{{ topico.titulo }}</h1>
        <router-link :to="topico.caminho" class="card-transparencia-link">
          <cartao>
            <template v-slot:imagem>
              <img class="card-img" :src="topico.imagem">
            </template>
            <template v-slot:legenda>
              <p>{{ topico.descricao }}</p>
            </template>
          </cartao>
        </router-link>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>
  .secao-transparencia {
    margin-top: 50px;
    margin-bottom: 50px;

    display: grid;

    gap: 20px;
    grid-template-columns: repeat( auto-fit, minmax(380px, 1fr) )
  }

  .hero {
    background-image: url('../assets/bg_transparencia.jpg');
  }

  .card-transparencia-link {
    text-decoration: none;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

</style>

<script>
import Cartao from '@/components/Cartao.vue'

export default {
  name: 'Transparencia',
  components: {
    Cartao,
  },
  data: () => ({
    documentos: Array(20).fill({ titulo: 'Teste' }),
    topicos: [
      {
        titulo: 'Institucional',
        caminho: '/transparencia/institucional',
        descricao: 'Acesse aqui documentos da transparência da AHBB|Rede Santa Casa.',
        imagem: require('../assets/card_transparencia_1.jpg'),
      },
      {
        titulo: 'Projetos',
        caminho: '/transparencia/projetos',
        descricao: 'Acesse documentos por projeto da AHBB|Rede Santa Casa.',
        imagem: require('../assets/card_transparencia_2.jpg'),
      },
      {
        titulo: 'Covid-19',
        caminho: '/transparencia/covid-19',
        descricao: 'Acesse documentos por projetos COVID-19 da AHBB|Rede Santa Casa.',
        imagem: require('../assets/card_covid_19_v2.webp'),
      },
    ],
  }),
  metaInfo: {
    title: 'Transparência',
  },
}
</script>
